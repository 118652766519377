<template>
  <div>
      <Slider />
      <Solution />
      <Services />
      <Benefits />


  </div>
</template>

<script>
export default {
    name: 'Home',
    components: {
       Slider: () => import('./slider'),
       Solution: () => import('./solution'),
       Benefits: () => import('./benefits'),
       Services: () => import('./services'),
    },

}
</script>

<style>

</style>